<template>
  <b-custom-tabs :tabs="tabs" />
</template>

<script>
import ActiveSupportRequestList from './ActiveSupportRequests/ActiveSupportRequestList.vue'

export default {
  data() {
    return {
      tabs: [{ title: 'Aktif Talepler', component: ActiveSupportRequestList }, { title: 'Geçmiş Talepler' }],
    }
  },
}
</script>

<style></style>
