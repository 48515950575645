<template>
  <searchable-table
    :items="[]"
    :fields="fields"
  />
</template>

<script>
export default {
  data() {
    return {
      fields: [
        { key: 'type', label: 'Talep Türü', sortable: true },
        { key: 'createdAt', label: 'Oluşturulma Tarihi', sortable: true },
      ],
    }
  },
}
</script>

<style></style>
